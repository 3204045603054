import { RiotRankedDivision, RiotRankedTier } from "~/types/riot";

export const getRankPointValue = (tier: RiotRankedTier, division: RiotRankedDivision, leaguePoints: number) => {
  const tierBaseLP = 100;

  if ([RiotRankedTier.MASTER, RiotRankedTier.GRANDMASTER, RiotRankedTier.CHALLENGER].includes(tier)) return (tierBaseLP * 28) + leaguePoints;

  const tierValues = {
    [RiotRankedTier.IRON]: 0,
    [RiotRankedTier.BRONZE]: tierBaseLP * 4,
    [RiotRankedTier.SILVER]: tierBaseLP * 8,
    [RiotRankedTier.GOLD]: tierBaseLP * 12,
    [RiotRankedTier.PLATINUM]: tierBaseLP * 16,
    [RiotRankedTier.EMERALD]: tierBaseLP * 20,
    [RiotRankedTier.DIAMOND]: tierBaseLP * 24,
  };

  const divisionValues = {
    [RiotRankedDivision.IV]: 0,
    [RiotRankedDivision.III]: tierBaseLP,
    [RiotRankedDivision.II]: tierBaseLP * 2,
    [RiotRankedDivision.I]: tierBaseLP * 3,
  };

  // @ts-ignore
  return tierValues[tier] + divisionValues[division] + leaguePoints;
};